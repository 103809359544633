.movementStatusContainer {
    height: 10px;
    position: relative;
    margin: 5px 0;
    left: 25%;
    width: 50%;
    overflow:hidden;
    background-color: #d0d0d0;
}

.movementStatus {
    top: 0;
    left: 0;
    height: 10px;
    position: absolute;
}

.movementStatusIndicator {
    position: absolute;
    width: 2px;
    height: 10px;
    background-color: #ffffff;
    top: 0;
}