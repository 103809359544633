.dialogContainer {
    display: flex;
    font-size: 1.4rem;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    width: 100%;
}

.label {
    cursor: pointer;
}