.currentCycleTable {
    max-height: 38rem;
    overflow: auto;
    width: 100%;
}

.currentCycleTable::-webkit-scrollbar {
    display: none;
}

.dailySummaryTable {
    max-height: 38rem;
    overflow: auto;
    width: 100%;
}

.crewMovementsTableContainer {
    display: flex;
    flex-direction: row;
}

.tableTitle {
    font-size: 1.6rem;
    color: white;
    font-weight: bold;
    height: 4rem;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    background-color: #004986;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crewLeaderTableCellDiv {
    text-align: left;
    color: #005ba8;
    cursor: pointer;
    margin-left: 1em;
    padding-left: 2.4rem;
}

.crewLeaderTableCellDivActive {
    background: url('../../../assets/icon-crew-active.svg') no-repeat left;
}