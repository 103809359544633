.currentCycleTable {
    max-height: 38rem;
    overflow: auto;
    width: 100%;
}

.currentCycleTable::-webkit-scrollbar {
    display: none;
}

.dailySummaryTable {
    max-height: 38rem;
    overflow: auto;
    width: 100%;
}

.tableContainer {
    display: flex;
    flex-direction: row;
}

.tableTitle {
    font-size: 1.6rem;
    color: white;
    font-weight: bold;
    height: 4rem;
    border-top-left-radius: 1.6rem;
    border-top-right-radius: 1.6rem;
    background-color: #004986;
    display: flex;
    justify-content: center;
    align-items: center;
}