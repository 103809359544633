.smpContainer {
    padding: 1.5rem;
    background-color: #fff;
    
    display: flex;
    flex-flow: column;
    height: 100%;
}

.innerFixed {
    flex-shrink: 1;
    flex-grow: 0;
    flex-basis: auto;
}

.innerRemaining {
    /*
    min-height: 20rem;
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 20rem;
    overflow-y: 100%;
    */
    height: 50rem;
    overflow-y: auto;
}

.saleMovesTitle {
    color:#003468;
    font-size:1.8rem;
    text-align:left;
    font-weight: normal;
}

.saleMovesHeader {
    display:flex;
    margin-bottom: 10px;
}

.totalDriversContainer {
    min-width:22.8rem;
    background-color:#f5f5f5;
    border-radius:4px;
    margin-right:10px;
    padding: 0 1.2rem;
}

.saleMovesFilterContainer {
    background-color:#f5f5f5;
    border-radius:4px;
    padding: 0 1.2rem;
    width: 100%;
    text-align: left;
}

.saleMovesFilterContainer h3,
.totalDriversContainer h3 {
    text-align:left;
    border-bottom:1px solid #797979;
    color:#000;
    font-size:1.6rem;
    padding-bottom: .75rem;
}

.totalDriversGrid {
    min-width: 22.8rem;
    display: grid;
    grid-template-columns: 50% 50%;
}

.metric p {
    color:#013468;
    font-size:1.3rem;
    font-weight:bold;
    margin-top: 0;
}

.metricValue {
    display:block;
    font-size:2rem;
}

.saleMovesFilterGrid {
    /*
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    */
    display: flex;
}

.saleMovesFilterType {
    margin-right: 75px;
}

.saleMovesFilterType h4 {
    font-size: 1.4em;
    margin: 0;
}

.saleMovesFilterType ul {
    list-style-type:none;
    font-size:1.3em;
    padding-inline-start:0;
    margin-top: .75rem;
}

.saleMovesFilterType ul li {
    margin-bottom: 1rem;
}

.error {
    color:#D01E1E;
}