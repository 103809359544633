.saleAlertBar {
    width: 100%;
    margin-bottom: 1rem;
    overflow-y: hidden;
}
.saleAlertBar ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-block-start: 0  !important;
    margin: .7rem 0;
}

.saleAlertBar ul li {
    background-color: #f0cdcf;
    color: #b60000;
    border-radius: 4px;
    padding: 0.6em 1em;
    text-align: left;
    margin-bottom: 1rem;
    overflow-y: hidden;
    font-size: 1.4rem;

}

.warningHeader {
    background: url('../../assets/warning.svg') no-repeat 1.6rem center;
    margin-left: 0 !important;
    padding-left: 5rem !important;
}