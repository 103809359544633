.container {
    min-height: 100vh;
    padding: 15px;
}

.tableTitle {
    font-size: 2.5rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #003468;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.crewMovementsContainer {
    margin-bottom: 2rem;
}