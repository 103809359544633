.bullMoveLabelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bulkMoveLabelValue {
    font-size: 2rem;
    font-weight: bold;
}

.bulkMoveLabelTitle {
    font-size: 1.4rem;
}
