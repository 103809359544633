.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    justify-content: space-between;
}

.spaceBetween {
    justify-content: space-between;
}

.title {
    font-size: 2.5rem;
    color: #003468;
    font-weight: bold;
    margin-right: 2.5rem;
}

.backButtonContainer {
    font-size: 2rem;
    color: #005ba8;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tableTitle {
    font-size: 2.5rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #003468;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.crewMovementsContainer {
    margin-bottom: 2rem;
}
