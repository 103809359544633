.saleMovesContainer {
    display: flex;
}

.dialogTable,
.saleMovesTable {
    border-collapse: separate;
    border-spacing: 0 1px;
    margin-bottom: 10px;
}

.dialogTable {
    width: 100%;
}

.saleMovesTable tbody:before {
    content:"@";
    display:block;
    line-height:0rem;
    text-indent:-99999rem;
}

.dialogTable tr:nth-child(even),
.saleMovesTable tr:nth-child(even) {background: #fff;}
.dialogTable tr:nth-child(odd),
.saleMovesTable tr:nth-child(odd) {background: #eee;}
.saleMovesTable tr:last-child td {border-bottom: 1px solid #797979;}

.saleMovesTable th,
.saleMovesTable td {
    text-align: left;
    font-size: 1.4em;
    padding: 1rem;
    min-width: 6rem !important;
    max-width: 6rem !important;
}

.rowHeaders {
    margin-right: 10px;
    font-weight: bold;
    min-width: 250px;
}

.rowHeaders td {
    min-width: 230px !important;
}

.tableTitle {
    background-color:#013468;
    color:#fff;
}

.tableHeader:first-child,
.tableTitle:first-child {
    border-radius: 4px 0 0 0;
}

.tableHeader:last-child,
.tableTitle:last-child {
    border-radius: 0 4px 0 0;
}

.tableHeader:only-child,
.tableTitle:only-child {
    border-radius: 4px 4px 0 0;
}

.tableHeader {
    background-color:#1952ce;
    color:#fff;
    position: relative;
    text-align: left;
}

.hidden {
    display: block;
    text-indent:-99999rem;
}

.rowHeaders .tableHeader {
    text-indent:0;
}

.dataTable {
    width: 100%;
    overflow-x: scroll;
}

.alert {
    background-color: #fcccc0;
    border-radius: 4px;
}

.paceValue {
    margin-left: 1.6rem;
    font-weight: bold;
}

.fast {
    background: url('../../assets/icon-fast.svg') no-repeat .65rem center #fcccc0;
    border-radius: 4px;
}

.good {
    background: url('../../assets/icon-good.svg') no-repeat 1rem center #e3fad1;
    border-radius: 4px;
}

.slow {
    background: url('../../assets/icon-slow.svg') no-repeat 1.35rem center #fff0c3;
    border-radius: 4px;
}

.down {
    background: url('../../assets/icon-down.svg') no-repeat .8rem center #babcbe;
    border-radius: 4px;
}

/* Dialog Table */
.dialogTable th,
.dialogTable td {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 1rem 2rem 1rem 1rem;
}

.dialogTitle {
    font-size: 2rem;
}

.dialogSearch {
    margin-bottom: 1rem;
}

.dialogSearch input {
    font-size: 1.4rem;
    width: 47%;
    padding: .5rem 1rem;
}

.dialogTable td.centered {
    text-align: center;
}

.titleContainer,
.filterContainer,
.driverSearchContainer {
    display: flex;
    justify-content: space-between;
}

.filterActive,
.filterInactive,
.filterClear,
.searchButton,
.sortDown,
.sortUp {
    width: 1.4rem;
    height: 1.4rem;
    display: inline-block;
    padding: 0 .25rem;
    cursor: pointer;
}

.filterActive {
    background: url('../../assets/icon-funnel-active.svg') no-repeat center center;
}

.filterInactive {
    background: url('../../assets/icon-funnel-inactive.svg') no-repeat center center;
}

.filterClear {
    background: url('../../assets/icon-close.svg') no-repeat center center;
}

.searchButton {
    background: url('../../assets/icon-search.svg') no-repeat center center;
}

.sortDown {
    vertical-align: bottom;
    background: url('../../assets/icon-arrow-dn.svg') no-repeat center center;
}

.sortUp {
    vertical-align: bottom;
    background: url('../../assets/icon-arrow-up.svg') no-repeat center center;
}

.dialogClose {
    display: block;
    width: 1.4rem;
    height: 1.4rem;
    background: url('../../assets/icon-dialog-close.svg') no-repeat center center;
    cursor: pointer;
    padding: 5px;
    margin-top: 10px;
}

.filterList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.filterList li {
    font-size: 1.4rem;
    margin: 0 0 1rem 0;
    padding: 0;
}

.filterList li input {
    margin-right: 2rem;
}

.mySelect {
    font-size: 1.4rem;
    width: 47%;
    padding: .5rem 1rem;
}

.noDataTitle {
    width: 100%;
    height: 100%;
    text-align: center;
}

.noDataTitle h2 {
    font-size: 2.5rem;
    color: #aaa;
}