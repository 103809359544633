.alertBar {
    width: 100%;
    background-color: #c33a00;
    color: #fff;
    border-radius: 4px;
    text-align: left;
    margin-bottom: 1rem;
    overflow-y: hidden;
}

.alertBar ul {
    list-style-type: none;
    padding-inline-start: 16px;
    margin-top: .7rem;
    margin-bottom: .7rem;
}

.alertBar ul li {
    display: inline-block;
    font-size: 1.4rem;
    margin-left: 2rem;
    font-weight: bold;
}

.alertBar ul li span {
    background-color: #fff;
    color: #4a4a4a;
    padding: .5rem .75rem;
    border-radius: 1rem;
    font-weight: bold;
    margin-right: .8rem;
    line-height: 3rem;
}

.flagHeader {
    background: url('../../assets/flag-white.svg') no-repeat left;
    margin-left: 0 !important;
    padding-left: 24px;
    font-weight: normal !important;
}