.headerCellDataClickable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.headerCellDataUnClickable {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: default;
}

.headerTextLabel {
    font-size: 1.4rem;
    margin-right: .5rem;
    font-weight: bold;
    max-width: 9rem;
    word-wrap: break-word;
}

/*
.tableContainer {
    overflow: auto;
    max-height: 38rem;
*/

.noDataHeader {
    color: #aaa;
}