.dropdownDiv {
    display: flex;
    width: 25%;
}

.dropdownLabel {
    font-weight: 300;
    font-size: 1.5rem;
    padding-right: 1rem;
    padding-top: 1.5rem;
}

.dropdown {
    width: 100%;
}